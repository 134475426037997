@use 'mixins/media' as *;

$gutter: 3rem;
$container-gutter-mobile: $gutter;
$container-gutter-tablet: 5rem;
$container-gutter-desktop: 7rem;

// Grid
$grid-column-count: 12;
$grid-baseline: 1.6rem;

// page dimensions
$page-width: 128rem;
$page-limit: $page-width + ($container-gutter-desktop * 2);
$spacing-top: 10rem;
$spacing-top-mobile: 5rem;

// Fonts
$font-family: 'Montserrat', Arial, tahoma, sans-serif;
$font-family-alt: 'Montserrat', Arial, tahoma, sans-serif;
$font-size: 2rem;
$font-size-min: 1.6rem;
$line-height: 1.5;

// Colors
$color-unthemed: #777;
$color-yellow: #fc0;
$color-dark: #232323;
$color-orange: #f69d23;
$color-error: #f00;

$color-alvogen: $color-yellow;
$color-alvotech: $color-orange;

$color-font: $color-dark;
$color-font-light: #999;
$color-copy: #757575; // was #808080, changed for WCAG compliance
$color-copy-light: #999;
$color-background: #fff;
$color-background-alt: #2b2b2b;
$color-background-variant-light: #f4f4f4;
$color-label: #000;
$color-placeholder: #ddd;
$color-placeholder-dark: #444;
$color-primary: #ccc !default;
$color-border: #eee;
$color-border-dark: #ccc;
$color-border-form: #e3e3e3;

// Timings
$transition-enter: 200ms;
$transition-leave: 500ms;
$transition-background: 500ms;

// Predefined break points (Min)
$min-1800: media-min(1800px);
$min-1680: media-min(1680px);
$min-1600: media-min(1600px);
$min-1440: media-min(1440px);
$min-1320: media-min(1320px);
$min-1280: media-min(1280px);
$min-1200: media-min(1200px);
$min-1080: media-min(1080px);
$min-1024: media-min(1024px);
$min-960: media-min(960px);
$min-840: media-min(840px);
$min-768: media-min(768px);
$min-720: media-min(720px);
$min-640: media-min(640px);
$min-600: media-min(600px);
$min-560: media-min(560px);
$min-540: media-min(540px);
$min-480: media-min(480px);
$min-420: media-min(420px);
$min-375: media-min(375px);
$min-360: media-min(360px);

// Predefined break points (Max)
$max-1800: media-max(1800px);
$max-1440: media-max(1440px);
$max-1280: media-max(1280px);
$max-1200: media-max(1200px);
$max-1080: media-max(1080px);
$max-1024: media-max(1024px);
$max-960: media-max(960px);
$max-840: media-max(840px);
$max-768: media-max(768px);
$max-720: media-max(720px);
$max-640: media-max(640px);
$max-600: media-max(600px);
$min-560: media-max(560px);
$max-540: media-max(540px);
$max-480: media-max(480px);
$max-420: media-max(420px);
$max-375: media-max(375px);
$max-360: media-max(360px);

// Segment
$segment-padding-mobile: 6rem;
$segment-padding: 13rem;
$layout-padding-top-mobile: 6rem;
$layout-padding-top: 9rem;
$layout-padding-bottom-mobile: 6rem;
$layout-padding-bottom: 6rem;

// vertically responsive breakpoints
$scale-height-sm: 685px;
$scale-height-md: 805px;
$scale-height-lg: 920px;

// vertical scaling baselines
$scale: 1;
$scale-lg: 1;
$scale-md: 0.95;
$scale-sm: 0.9;

// Navigation Button
$navigation-button-width: var(--navigation-button-width, 0rem);

$z-navigation: 1005;
$z-topNavigation: 1006;
$z-overlay: 1105;
