@use "sass:math";
@use 'mixins/brand' as *;
@use 'mixins/responsive' as *;
@use 'mixins/reset' as *;
@use 'variables' as *;

@mixin fit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin nav-offset-margin() {
  @media (min-width: $min-960) {
    padding-right: 0;
    margin-right: var(--navigation-button-width);
  }
}

@mixin nav-offset-padding() {
  @media (min-width: $min-960) {
    margin-right: 0;
    padding-right: var(--navigation-button-width);
  }
}

@mixin segment-top($desktop: $segment-padding, $mobile: $segment-padding-mobile) {
  @include supports-math() {
    padding-top: responsive($mobile, $desktop, 72rem, 128rem);
  }

  @include no-supports-math() {
    padding-top: $mobile;

    @media (min-width: $min-720) {
      padding-top: $desktop;
    }
  }
}

@mixin segment-bottom($desktop: $segment-padding, $mobile: $segment-padding-mobile) {
  @include supports-math() {
    padding-bottom: responsive($mobile, $desktop, 72rem, 128rem);
  }

  @include no-supports-math() {
    padding-bottom: $mobile;

    @media (min-width: $min-720) {
      padding-bottom: $desktop;
    }
  }
}

@mixin segment($desktop: $segment-padding, $mobile: $segment-padding-mobile) {
  @include segment-top($desktop, $mobile);
  @include segment-bottom($desktop, $mobile);
  @include nav-offset-margin();
}

@mixin split-segment(
  $desktop: math.div($segment-padding, 2),
  $mobile: math.div($segment-padding-mobile, 2)
) {
  @include segment-top($desktop, $mobile);
  @include segment-bottom($desktop, $mobile);
}

@mixin article-segment($desktop: $gutter * 1.5, $mobile: $gutter) {
  @include segment-top($desktop, $mobile);
  @include segment-bottom($desktop, $mobile);
  @include nav-offset-margin();
}

@mixin container() {
  margin: 0 auto;

  padding-left: $container-gutter-mobile;
  padding-right: $container-gutter-mobile;
  max-width: $page-width + ($container-gutter-mobile * 2);

  @media (min-width: $min-720) {
    padding-left: $container-gutter-tablet;
    padding-right: $container-gutter-tablet;
    max-width: $page-width + ($container-gutter-tablet * 2);
  }

  @media (min-width: $min-1080) {
    padding-left: $container-gutter-desktop;
    padding-right: $container-gutter-desktop;
    max-width: $page-width + ($container-gutter-desktop * 2);
  }
}

@mixin containerNoLimit() {
  padding-left: $container-gutter-mobile;
  padding-right: $container-gutter-mobile;

  @media (min-width: $min-720) {
    padding-left: $container-gutter-tablet;
    padding-right: $container-gutter-tablet;
  }

  @media (min-width: $min-1080) {
    padding-left: $container-gutter-desktop;
    padding-right: $container-gutter-desktop;
  }
}

// typography
@mixin heading($size-mobile: 3.2rem, $size: 4.8rem) {
  @include reset-heading();
  line-height: 1;
  // font-family: $font-family;
  font-weight: 700;

  @if ($size==$size-mobile) {
    font-size: $size;
  } @else {
    font-size: responsive($size-mobile, $size);
  }
}

@mixin h1($size-mobile: 3.6rem, $size: 7.2rem) {
  @include heading($size-mobile, $size);
  line-height: 1;
  letter-spacing: math.div(1, 72) * -1em;
}

@mixin h2($size-mobile: 3rem, $size: 5.2rem) {
  @include heading($size-mobile, $size);
  line-height: 1.25;
  font-weight: 700;
}

@mixin h3($size-mobile: 2rem, $size: 2.8rem) {
  @include heading($size-mobile, $size);
  line-height: 1.25;
  font-weight: 700;
}

@mixin h4($size-mobile: 1.8rem, $size: 2.4rem) {
  @include heading($size-mobile, $size);
  line-height: 1.25;
  font-weight: 700;
}

@mixin h5($size-mobile: 1.8rem, $size: 1.8rem) {
  @include heading($size-mobile, $size);
  line-height: 1.25;
  font-weight: 600;
}

@mixin copy($size-mobile: 1.4rem, $size: 1.8rem) {
  @include reset-paragraph();
  font-weight: 400;
  line-height: 1.666;
  color: $color-copy;

  @if ($size==$size-mobile) {
    font-size: $size;
  } @else {
    font-size: responsive($size-mobile, $size);
  }

  b,
  strong {
    font-weight: 500;
    color: $color-font;

    :global(.dark-theme) & {
      color: #fff;
    }
  }

  :global(.dark-theme) & {
    color: #fff;
  }

  :global(body.dark) & {
    b,
    strong {
      color: #fff;
      font-weight: 400;
    }
  }

  :global(body.brand) & {
    color: $color-dark;

    b,
    strong {
      font-weight: 500;
    }
  }
}

@mixin blockquote($size-mobile: 1.4rem, $size: 1.8rem) {
  @include copy($size-mobile, $size);
  position: relative;

  @media (max-width: $max-720) {
    padding-left: 3.5rem;
    border-left: 0.2rem solid;
    border-color: brand();

    :global(body.brand) & {
      border-color: $color-dark;
    }

    p:first-child {
      &::before {
        content: '“';
        float: left;
        margin-right: 2rem;
        margin-top: -0.1em;
        font-size: 3.1em;
        font-weight: 700;
        line-height: 1;
        color: $color-font;

        :global(body.dark) & {
          color: #fff;
        }

        :global(body.brand) & {
          color: $color-dark;
        }
      }
    }
  }

  @media (min-width: $min-720) {
    &::before {
      content: '“';
      @include brandColor(color);
      position: absolute;
      top: -0.1em;
      left: -0.97em;
      font-size: 3.1em;
      font-weight: 700;
      line-height: 1;

      :global(body.brand) & {
        color: $color-dark;
      }
    }
  }
}

@mixin anchor() {
  @include reset-anchor();
  color: $color-font;
  text-decoration: underline;
  transition-property: color, border;
  transition-duration: $transition-leave;

  :global(.dark-theme) &,
  :global(body.dark) & {
    color: #fff;
  }

  &:hover,
  &:focus {
    @include brandColor(color);
    transition-property: color, border;
    transition-duration: $transition-enter;

    :global(body.brand) & {
      @include brandColorOverride(color, #fff);
    }
  }
}

@mixin unordered-list() {
  @include reset-list();
  position: relative;
  padding-left: math.div(67, 18) * 1em;
  font-size: responsive(1.4rem, 1.8rem);

  li {
    @include copy();
    position: relative;

    &::before {
      content: '';
      @include brandColor(background-color);
      position: absolute;
      font-weight: bold;
      margin-top: 0.66em;
      left: math.div(67, 18) * -1em;
      width: math.div(37, 18) * 1em;
      height: 0.2rem;

      :global(body.brand) & {
        @include brandColorOverride(background-color, $color-dark);
      }
    }
  }
}

@mixin ordered-list() {
  @include reset-list();
  counter-reset: ordered-list;

  li {
    @include copy();
    position: relative;
    display: flex;
    flex-direction: column;
    counter-increment: ordered-list;

    &::before {
      content: counter(ordered-list, decimal-leading-zero);
      flex: 0 0 auto;
      margin-bottom: 1em;
      order: -1;
      font-size: 1.2rem;
      line-height: 2.4rem;
      font-weight: bold;
      color: $color-dark;

      :global(body.brand) & {
        color: $color-dark;
      }

      :global(body.dark) & {
        color: #fff;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 3.5rem;
      background-color: brand();
      flex: 0 0 auto;
      line-height: 2.4rem;
      margin-top: 1.2rem;
      // margin-left: math.div(22, 18) * 1em;
      width: math.div(37, 18) * 1em;
      height: 0.2rem;

      :global(body.brand) & {
        background-color: $color-dark;
      }
    }
  }
}

@mixin list() {
  @include unordered-list;
}

@mixin render-copy($size-mobile: 1.4rem, $size: 1.8rem) {
  h1 {
    @include h3();
    margin-bottom: math.div(30, 28) * 0.5em;
    max-width: 62rem;

    &:not(:first-child) {
      margin-top: math.div(30, 28) * 1.5em;
    }
  }

  h2 {
    @include h4();
    margin-bottom: math.div(30, 24) * 0.5em;
    max-width: 62rem;

    &:not(:first-child) {
      margin-top: math.div(30, 24) * 1.5em;
    }
  }

  h3 {
    @include h5();
    margin-bottom: math.div(30, 18) * 0.5em;
    max-width: 62rem;

    &:not(:first-child) {
      margin-top: math.div(30, 18) * 1.5em;
    }
  }

  h4 {
    @include h5();
    margin-bottom: math.div(30, 18) * 0.5em;
    max-width: 62rem;

    &:not(:first-child) {
      margin-top: math.div(30, 18) * 1.5em;
    }
  }

  p {
    @include copy($size-mobile, $size);
    margin-bottom: 1.667em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p,
  li {
    a {
      @include anchor();
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
    }
  }

  ul {
    @include unordered-list();
    margin-bottom: 1.75em;

    li {
      @include copy($size-mobile, $size);
    }

    &:not(:first-child) {
      margin-top: 1.75em;
    }

    &:last-child {
      margin-bottom: 0;
    }

    li:not(:last-child) {
      margin-bottom: 1.75em;
    }
  }

  ol {
    @include ordered-list();
    margin-bottom: 2.25em;

    li {
      @include copy($size-mobile, $size);
    }

    &:not(:first-child) {
      margin-top: 2em;
    }

    &:last-child {
      margin-bottom: 0;
    }

    li:not(:last-child) {
      margin-bottom: 2.25em;
    }
  }

  blockquote {
    @include blockquote();
  }
}

@mixin brandColor($property: 'background', $opacity: 1) {
  // @warn "brandColor has been deprecated. Use brand() scss color function instead.";
  #{$property}: brand($opacity);
}

@mixin brandColorOverride($property: 'background', $color: $color-unthemed, $opacity: 1) {
  // @warn "brandColorOverrise has been deprecated. Use regular color property instead.";
  #{$property}: rgba($color, $opacity);
}

@mixin brandColorShadow($shadow: 0 0 0 0, $opacity: 1) {
  // @warn "brandColorShadow has been deprecated. Use brand() scss color function to define the color instead.";
  box-shadow: $shadow rgba(var(--theme-rgb-color), $opacity);
}

@mixin tagline() {
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: math.div(1, 12) * 1em;
  text-transform: uppercase;
}

@mixin taglineWithLine($size: 5.5rem) {
  @include tagline;

  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: block;

    margin-right: 2rem;

    height: 0.2rem;
    width: $size;
    min-width: 3.6rem;
    max-width: 10vw;

    background-color: brand();
  }

  :global(body.brand) &::before {
    background-color: $color-dark;
  }
}

@mixin trailingLine($size: 5.5rem, $type: 'brand') {
  display: flex;
  align-items: center;

  &::after {
    content: '';
    display: block;

    margin-left: 1.5rem;

    height: 0.2rem;
    width: $size;
    min-width: min($size, 3.6rem);
    max-width: 10vw;

    @if ($type== 'brand') {
      @include brandColor;

      :global(body.brand) & {
        @include brandColorOverride(background, $color-dark);
      }
    } @else {
      background: $color-dark;

      :global(body.dark) & {
        background: #fff;
      }
    }
  }
}

@mixin headingLine($size: 7.7rem, $space: 1em) {
  &::after {
    content: '';
    display: block;

    margin-top: $space;
    margin-bottom: $space;
    height: 0.2rem;
    width: $size;
    min-width: 3.6rem;
    max-width: 10vw;

    @include brandColor;

    :global(body.brand) & {
      @include brandColorOverride(background, $color-dark);
    }
  }
}

@mixin backgroundColor() {
  transition: color 1s;

  :global(body.dark) & {
    color: #fff;
  }
}

@mixin formLabel($size-mobile: 1.2rem, $size: 1.4rem) {
  @include reset-paragraph();
  display: block;
  font-weight: 500;
  line-height: math.div(24, 14);
  color: $color-font;

  :global(body.dark) & {
    color: #fff;
  }

  @if ($size==$size-mobile) {
    font-size: $size;
  } @else {
    font-size: responsive($size-mobile, $size);
  }
}

@mixin formInput($size-mobile: 1.4rem, $size: 1.8rem) {
  @include reset-input();
  height: 4rem;
  width: 100%;
  padding: 0 0 0.5rem;
  color: $color-dark;
  border-bottom: 1px solid $color-border-form;
  background: transparent;

  :global(body.dark) & {
    color: #fff;
    border-bottom: 1px solid $color-copy;
  }

  :global(body.brand) & {
    border-bottom: 1px solid $color-dark;
  }

  @media (min-width: $min-720) {
    height: 5.5rem;
  }

  @if ($size==$size-mobile) {
    font-size: $size;
  } @else {
    font-size: responsive($size-mobile, $size);
  }

  &:focus {
    border-bottom-color: $color-dark;

    :global(body.dark) & {
      border-bottom-color: #fff;
    }
  }

  &.disabled {
    color: $color-placeholder;

    :global(body.brand) &,
    :global(body.dark) & {
      color: $color-placeholder-dark;
    }
  }

  // &:required {
  //   @include brandColor(border-bottom-color);

  //   :global(body.brand) & {
  //     @include brandColorOverride(border-bottom-color, #fff);
  //   }
  // }

  &[aria-invalid='true'] {
    @include brandColor(border-bottom-color);

    :global(body.brand) & {
      @include brandColorOverride(border-bottom-color, #fff);
    }
  }
}

@mixin dropCap() {
  @media (max-width: $max-720) {
    &::first-letter {
      float: left;
      margin-top: 0.09em;
      margin-right: 0.8rem;
      font-size: 3.4em;
      line-height: 0.8;
      font-weight: 700;
      color: $color-font;

      :global(.dark-theme) &,
      :global(body.dark) & {
        color: #fff;
      }
    }
  }
}

// feature query mixin for css-grid
@mixin no-supports-grid() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }

  @supports not (display: grid) {
    @content;
  }
}

@mixin supports-grid() {
  @supports (display: grid) {
    @content;
  }
}

// feature query mixin for css-math
@mixin no-supports-math() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }

  @supports not (padding: clamp(3rem, 30vw, 5rem)) {
    @content;
  }
}

@mixin supports-math() {
  @supports (padding: clamp(3rem, 30vw, 5rem)) {
    @content;
  }
}

@mixin label($size-mobile: 1.2rem, $size: 1.2rem) {
  font-size: responsive(1.2rem, 1.2rem);
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: $color-label;

  :global(body.dark) & {
    color: #fff;
  }
}
